import React from "react"
import Layout from "../../components/member/Layout"
import People from "../../components/member/People/People"

export default () => {
  const pageSlug = "people"
  const pageTitle = "インスタ"
  return (
    <Layout pageSlug={pageSlug} pageTitle={pageTitle}>
      <People />
    </Layout>
  )
}
