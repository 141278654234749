import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Typography from "@material-ui/core/Typography";
import Img from "gatsby-image/withIEPolyfill";
import InstagramIcon from "@material-ui/icons/Instagram";
import { makeStyles } from "@material-ui/core/styles";
import TwitterIcon from "@material-ui/icons/Twitter";
import CreateIcon from "@material-ui/icons/Create";
import SurroundSoundIcon from "@material-ui/icons/SurroundSound";
// import LinkIcon from "@material-ui/icons/Link";
import FacebookIcon from "@material-ui/icons/Facebook";
import YouTubeIcon from "@material-ui/icons/YouTube";
import LanguageIcon from "@material-ui/icons/Language";

import styles from "./People.module.scss";
const iconColor = "#262b35";

const useStyles = makeStyles(() => ({
  icon: {
    fill: iconColor,
    fontSize: "1.4rem",
  },
}));
export default () => {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query {
      imageAyana: file(relativePath: { eq: "member/people/ayana.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 450) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageKobadai: file(relativePath: { eq: "member/people/kobadai.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 450) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageGofuku: file(relativePath: { eq: "member/people/gofuku.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 450) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageShogosamata: file(
        relativePath: { eq: "member/people/shogosamata.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 450) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageKumafarm: file(relativePath: { eq: "member/people/kumafarm.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 450) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageInadaze: file(relativePath: { eq: "member/people/inadaze.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 450) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  return (
    <div className={styles.root}>
      <Typography variant="h1">メンバー</Typography>
      <Typography className={styles.lead}>
        箕澤屋の中の人と、サポートしてくれている方々の紹介です。
      </Typography>

      <div className={styles.peopleContainer}>
        <div className={styles.peopleItem}>
          <div className={styles.peopleItem__container}>
            <Img
              fluid={data.imageAyana.childImageSharp.fluid}
              alt=""
              className={styles.peopleItem__image}
            />
            <div>
              <Typography variant="h2" className={styles.peopleItem__name}>
                Ayana
              </Typography>
              <Typography className={styles.peopleItem__caption}>
                なっかなか箕澤屋に現れないプロジェクト代表です。最近はNYのコミュニティガーデンに関わり始め、
                <a
                  href="https://app.slack.com/client/T01T8QECA4B/C01T3UL5A5U/details/top"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.link}
                >
                  ニューヨーク支部
                </a>
                としてSlackで徒然日記を書いてます。
              </Typography>
              <div className={styles.peopleItem__icon}>
                <a
                  href="https://www.instagram.com/misawayanohanashi/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Instagram"
                >
                  <InstagramIcon className={classes.icon} />
                </a>
                <a
                  href="https://twitter.com/ayaos_eats"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Twitter"
                >
                  <TwitterIcon className={classes.icon} />
                </a>
                <a
                  href="https://blog.misawaya.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Blog"
                >
                  <CreateIcon className={classes.icon} />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.peopleItem}>
          <div className={styles.peopleItem__container}>
            <Img
              fluid={data.imageKobadai.childImageSharp.fluid}
              alt=""
              className={styles.peopleItem__image}
            />
            <div>
              <Typography variant="h2" className={styles.peopleItem__name}>
                コバダイ
              </Typography>
              <Typography className={styles.peopleItem__caption}>
                代表の代わりに箕澤屋を見ている現地管理人。近ごろは山にハマっているらしい。
                <a
                  href="https://soundcloud.com/kobadai"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Instagram"
                >
                  SoundCloud
                </a>
                で作業BGMとしてもよさげな曲を土間から発信中。
              </Typography>

              <div className={styles.peopleItem__icon}>
                <a
                  href="https://www.instagram.com/kobadai_misawaya/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <InstagramIcon className={classes.icon} />
                </a>
                <a
                  href="https://soundcloud.com/kobadai"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Sound Cloud"
                >
                  <SurroundSoundIcon className={classes.icon} />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.peopleItem}>
          <div className={styles.peopleItem__container}>
            <Img
              fluid={data.imageGofuku.childImageSharp.fluid}
              alt=""
              className={styles.peopleItem__image}
            />
            <div>
              <Typography variant="h2" className={styles.peopleItem__name}>
                gofuku Botanical atelier
              </Typography>
              <Typography className={styles.peopleItem__caption}>
                五福
                ボタニカルアトリエのKahoriさん。ヴィーガンやグルテンフリー、ヨガやアロマなど、健康的で自然なライフスタイルを届けています。
              </Typography>

              <div className={styles.peopleItem__icon}>
                <a
                  href="https://www.instagram.com/gofuku_botanical_atelier/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Instagram"
                >
                  <InstagramIcon className={classes.icon} />
                </a>
                <a
                  href="https://www.youtube.com/channel/UC6BC_X9bDK5TTxqdwFZfBtw"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Youtube"
                >
                  <YouTubeIcon className={classes.icon} />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.peopleItem}>
          <div className={styles.peopleItem__container}>
            <Img
              fluid={data.imageShogosamata.childImageSharp.fluid}
              alt=""
              className={styles.peopleItem__image}
            />
            <div>
              <Typography variant="h2" className={styles.peopleItem__name}>
                Shogo Samata
              </Typography>
              <Typography className={styles.peopleItem__caption}>
                自給自足の畑が似合ってしまう音楽家とはこの人。コバダイ氏の動画作品のBGMとしてもたびたび登場しています。
              </Typography>

              <div className={styles.peopleItem__icon}>
                <a
                  href="https://www.instagram.com/shogosamata/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Instagram"
                >
                  <InstagramIcon className={classes.icon} />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCKhb63S1mcFnfTdjGOvAmdA"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Youtube"
                >
                  <YouTubeIcon className={classes.icon} />
                </a>
                <a
                  href="https://soundcloud.com/shogosamata"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Sound Cloud"
                >
                  <SurroundSoundIcon className={classes.icon} />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.peopleItem}>
          <div className={styles.peopleItem__container}>
            <Img
              fluid={data.imageKumafarm.childImageSharp.fluid}
              alt=""
              className={styles.peopleItem__image}
            />
            <div>
              <Typography variant="h2" className={styles.peopleItem__name}>
                熊ファーム
              </Typography>
              <Typography className={styles.peopleItem__caption}>
                伊那谷で無農薬・無化学肥料の自然栽培で野菜を作っている熊ファームのYukoさん。箕澤屋で結婚式、マルシェ出店など、いつもサポートしてくれています。
              </Typography>

              <div className={styles.peopleItem__icon}>
                <a
                  href="https://www.instagram.com/kuma_farm/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Instagram"
                >
                  <InstagramIcon className={classes.icon} />
                </a>
                <a
                  href="https://www.facebook.com/kumafarm.2015/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="linktree"
                >
                  <FacebookIcon className={classes.icon} />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.peopleItem}>
          <div className={styles.peopleItem__container}>
            <Img
              fluid={data.imageInadaze.childImageSharp.fluid}
              alt=""
              className={styles.peopleItem__image}
            />
            <div>
              <Typography variant="h2" className={styles.peopleItem__name}>
                IN A DAZE BREWING
              </Typography>
              <Typography className={styles.peopleItem__caption}>
                信州伊那谷のマイクロブルワリー。自家農園とのコラボもしている箕澤屋仲良しブルワリーです。
              </Typography>

              <div className={styles.peopleItem__icon}>
                <a
                  href="https://www.instagram.com/inadazebrewing/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Instagram"
                >
                  <InstagramIcon className={classes.icon} />
                </a>
                <a
                  href="https://www.inadazebrewing.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="In a daze brewing"
                >
                  <LanguageIcon className={classes.icon} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
